import { formatNumber } from "@core/utils/formatting";
import * as React from "react";
import { Link } from "react-router-dom";
import { Circle, ServiceTypeBadge } from "#components/index.ts";
import useClickOutside from "#helpers/hooks/useClickOutside.ts";
import useTabOutside from "#helpers/hooks/useTabOutside.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { SERVICE_DESCRIPTIONS } from ".";
import * as styles from "./style.scss";
import ServiceProtocol from "#components/ServiceProtocol/index.tsx";

export interface Props {
  isHighlighted: boolean;
}

interface DescriptionItem {
  key: string; // Keys must be strings to make them unique
  keyContent?: React.ReactNode; // Not all keys can be pure strings
  value: React.ReactNode;
}

const SpeedyItem: React.FC<Props> = ({ isHighlighted }) => {
  const ds = useCurrentDataset();

  const highlightedRef = React.useRef<HTMLDivElement>(null);
  const [isServiceHighlighted, setIsServiceHighlighted] = React.useState<boolean>(false);
  useClickOutside(() => {
    if (isServiceHighlighted) setIsServiceHighlighted(false);
  }, highlightedRef);
  useTabOutside(() => {
    if (isServiceHighlighted) setIsServiceHighlighted(false);
  }, highlightedRef);
  React.useEffect(() => {
    if (isServiceHighlighted) highlightedRef.current?.getElementsByTagName("a")[0]?.focus();
  }, [isServiceHighlighted]);
  React.useEffect(() => {
    if (isHighlighted) setIsServiceHighlighted(true);
  }, [isHighlighted]);
  if (!ds) return null;
  const descriptionItems: DescriptionItem[] = [];

  // Variables
  const consoleDatasetPath = ds && ds.owner ? "/" + ds.owner.accountName + "/" + ds.name : undefined;
  const consoleServicePath = `${consoleDatasetPath}/sparql`;

  // Header element
  descriptionItems.push({
    key: "Type",
    value: "Speedy",
  });
  descriptionItems.push({
    key: "Status",
    value: (
      <div className="flex center">
        <Circle color="green" />
        <div className={"pl-2"}>Running</div>
      </div>
    ),
  });
  descriptionItems.push({
    key: "Protocol",
    value: <ServiceProtocol serviceType="speedy" />,
  });
  descriptionItems.push({
    key: "Statements",
    value: formatNumber(ds.statements || 0),
  });
  descriptionItems.push({
    key: "Up-to-date",
    value: "Always",
  });
  return (
    <div className={isServiceHighlighted ? styles.addHighlight : styles.noHighlight} ref={highlightedRef}>
      <div className="white shadow my-5 py-4 px-5" id="Speedy">
        <div className="flex center">
          <ServiceTypeBadge type="speedy" />
          <div className={styles.title}>
            <h3>
              <Link
                to={{
                  pathname: consoleServicePath,
                  state: { serviceName: "Speedy" },
                }}
              >
                Speedy
              </Link>
            </h3>
          </div>
        </div>
        <p className="mt-2 mx-2">{SERVICE_DESCRIPTIONS["speedy"]}</p>
        <dl key="ServiceDescription" className="mb-2 mx-2">
          {descriptionItems.map((item) => (
            <React.Fragment key={"Fragment" + item.key}>
              <dt>{item.keyContent ? item.keyContent : item.key}</dt>
              <dd>{item.value && item.value !== 0 ? item.value : "N/A"}</dd>
            </React.Fragment>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default SpeedyItem;
