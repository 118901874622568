import formatNumber from "@core/utils/formatting";
import {
  Alert,
  Button,
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import type { Prefix } from "@triply/utils";
import type { SparqlUpdateResponse } from "@triply/utils/Models";
import FontAwesomeIcon from "#components/FontAwesomeIcon/index.tsx";
import IRI from "#components/IRI/index.tsx";
import useAcl from "#helpers/hooks/useAcl.ts";
import type { PrefixUpdate } from "#reducers/datasetManagement.ts";
import ErrorResponse from "../QueryResults/Visualizations/Error";
import type { CacheHeader } from "../SparqlUtils";
import * as styles from "./styles.scss";

interface Props {
  className?: string;
  loading?: boolean;
  error?: Error;
  duration?: number;
  delay?: number;
  cache?: CacheHeader;
  response?: SparqlUpdateResponse;
  datasetPath: string;
  prefixes: Prefix[];
  onCreatePrefix?: (prefix: PrefixUpdate) => Promise<true | string>;
  onExecuteUpdate?: () => void | undefined;
  queryDirty?: boolean;
}

type Tabs = "Graphs" | "Added" | "Removed";

const SparqlUpdateResults: React.FC<Props> = ({
  className,
  loading,
  error,
  duration,
  delay = 0,
  cache,
  response,
  datasetPath,
  prefixes,
  onCreatePrefix,
  onExecuteUpdate,
  queryDirty,
}) => {
  const acl = useAcl();
  const totalFormalation = response?.totals.isEstimate ? "Estimated total" : "Total";
  return (
    <>
      <div className={getClassName(styles.visualizationSelectorContainer, className)}>
        {loading && (
          <Chip
            className="ml-3"
            label={"Fetching results"}
            icon={<FontAwesomeIcon icon="spinner-third" spin />}
            size="small"
          />
        )}
        {!loading && !!error && duration !== undefined && (
          <Chip className="ml-3" color="error" label={`${duration / 1000} seconds`} size="small" />
        )}
        {!loading && !error && duration !== undefined && (
          <Chip
            className="ml-3"
            label={
              <>
                {`${formatNumber(duration / 1000, "0,0.000")} seconds`}
                {acl.check({ action: "seeQueryDelayAndCacheInfo" }).granted && (
                  <>
                    {delay >= 100 && (
                      <Tooltip title={`${formatNumber(delay / 1000, "0,0.000")} seconds in queue`}>
                        <span className="ml-2">
                          <FontAwesomeIcon icon="hourglass-clock" fixedWidth />
                        </span>
                      </Tooltip>
                    )}
                    {cache === "HIT" && (
                      <Tooltip title="Cache hit">
                        <span className="ml-2">
                          <FontAwesomeIcon icon={["fas", "database"]} fixedWidth />
                        </span>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            }
            size="small"
          />
        )}
      </div>
      <Divider />
      <div className={styles.resultsContainer}>
        {error && <ErrorResponse error={error} />}
        {response && (
          <Alert
            severity={response.applied === false ? "info" : "success"}
            action={
              onExecuteUpdate && (
                <div>
                  <Tooltip title={queryDirty && "The query has changed in the meantime, please re-execute the preview"}>
                    <div>
                      <Button
                        color="warning"
                        variant="contained"
                        onClick={onExecuteUpdate}
                        disabled={queryDirty || loading || response.applied}
                        startIcon={<FontAwesomeIcon icon={queryDirty ? "exclamation-triangle" : ["fas", "play"]} />}
                      >
                        Apply changes
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              )
            }
          >
            {response.applied === false ? (
              <Typography variant="body1">This is a preview of the changes that will be made.</Typography>
            ) : (
              <Typography variant="body1">Update was successfull!</Typography>
            )}
            <dl>
              <dt>{totalFormalation} added statements:</dt>
              <dd>{formatNumber(response.totals.addedStatementCount)}</dd>
              <dt>{totalFormalation} removed statements:</dt>
              <dd>{formatNumber(response.totals.removedStatementCount)}</dd>
            </dl>
            <TableContainer className={styles.disableTableWidth} component={Paper} variant="outlined">
              <Table size="small" className={styles.disableTableWidth}>
                <TableHead>
                  <TableRow>
                    <TableCell>Graph</TableCell>
                    <TableCell>Statements after update</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(response.graphs).map(([graphName, counts]) => {
                    const direction =
                      counts.countAfter === counts.countBefore
                        ? ""
                        : counts.countAfter > counts.countBefore
                          ? "+"
                          : "-";
                    return (
                      <TableRow key={graphName}>
                        <TableCell component="th" scope="row">
                          <IRI
                            position="graph"
                            datasetPath={datasetPath}
                            prefixes={prefixes}
                            onCreatePrefix={onCreatePrefix}
                          >
                            {graphName}
                          </IRI>
                        </TableCell>
                        <TableCell>
                          {formatNumber(counts.countAfter)}
                          {" ("}
                          <Typography
                            component="span"
                            color={direction === "+" ? "success.main" : direction === "-" ? "error" : undefined}
                            variant="overline"
                          >
                            {direction}
                            {formatNumber(Math.abs(counts.countAfter - counts.countBefore))}
                          </Typography>
                          {")"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Alert>
        )}
      </div>
    </>
  );
};

export default SparqlUpdateResults;
