import { IconButton } from "@mui/material";
import * as React from "react";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import { clearClassDescriptions } from "#reducers/resourceEditorDescriptions.ts";
import { ConfirmationDialog, Dialog, FontAwesomeIcon } from "../../components";
import useConstructUrlToApi from "../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../reducers/datasetManagement";
import { getGraphs } from "../../reducers/graphs";
import PropertyForm from "./Forms/Property";
import type { PropertyData } from "./Forms/Types";
import type { PropertyInfo } from "./ClassInfo";

const factory = factories.compliant;

const EditProperty: React.FC<{
  group?: {
    groupIri: string;
    groupName?: string;
    groupOrder?: number;
  };
  propertyInfo: PropertyInfo;
}> = ({ propertyInfo, group }) => {
  const [open, setOpen] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const currentDs = useCurrentDataset()!;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${datasetPath}/sparql`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();

  const onClose = React.useCallback(() => {
    isDirty ? setConfirmationDialogOpen(true) : setOpen(false);
  }, [isDirty]);

  const initialValues: PropertyData = {
    id: propertyInfo.path,
    label: propertyInfo.propertyShapeLabel || "",
    relationType: !!propertyInfo.propertyRange ? "object" : "datatype",
    class: propertyInfo.propertyRange,
    datatype: propertyInfo.propertyShapeDataType,
    defaultValue: propertyInfo.propertyShapeDefaultValue,
    description: propertyInfo.propertyShapeDescription,
    maxCount: propertyInfo.propertyMax ? propertyInfo.propertyMax + "" : undefined,
    minCount: propertyInfo.propertyMax ? propertyInfo.propertyMin + "" : undefined,
    order: propertyInfo.order,
    group: group
      ? {
          id: group.groupIri,
          label: group?.groupName || group.groupIri,
          order: group.groupOrder,
        }
      : undefined,
  };

  return (
    <>
      <IconButton aria-label="Edit property" onClick={() => setOpen(true)} size="small">
        <FontAwesomeIcon icon="pencil" />
      </IconButton>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          setOpen(false);
        }}
        onClose={() => setConfirmationDialogOpen(false)}
        title="Are sure you want to close this form?"
        actionLabel="Close"
        description="If you close the form now all changes will be lost"
      />

      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth title="Edit property" closeButton>
        <div className="px-5 pb-5">
          <PropertyForm
            onDirty={setDirty}
            initialValues={initialValues}
            onSubmit={async (changedProperty: PropertyData) => {
              const propertyLabel = changedProperty.label?.trim();
              const propertyDescription = changedProperty.description?.trim();
              const minCount = changedProperty.minCount;
              const maxCount = changedProperty.maxCount;
              const defaultValue = changedProperty.defaultValue;

              const datatypeChanged = initialValues.datatype !== changedProperty.datatype;
              const classChanged = initialValues.class !== changedProperty.class;
              const labelChanged = initialValues.label !== propertyLabel;
              const descriptionChanged = initialValues.description !== propertyDescription;
              const maxCountChanged = initialValues.maxCount !== maxCount;
              const minCountChanged = initialValues.minCount !== minCount;
              const orderChanged = initialValues.order !== changedProperty.order;
              const groupChanged = initialValues.group?.id !== changedProperty.group?.id;
              const defaultValueChanged = initialValues.defaultValue !== changedProperty.defaultValue;
              const iriChanged = initialValues.id !== changedProperty.id;

              const query = `
              prefix sh: <http://www.w3.org/ns/shacl#>
              prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
              prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
              prefix owl: <http://www.w3.org/2002/07/owl#>
              insert {
                ?propertyShape sh:name ?label;
                              sh:description ?description;
                              sh:datatype ?datatype;
                              sh:class ?class;
                              sh:path ?newIri;
                              sh:minCount ?minCount;
                              sh:maxCount ?maxCount;
                              sh:defaultValue ?defaultValue;
                              sh:order ?order;
                              sh:group ?group.
                              } where {
                bind(${termToString(factory.namedNode(propertyInfo.propertyShape))} as ?propertyShape)
                values (?datatype ?class ?label ?description ?maxCount ?minCount ?order ?group ?defaultValue ?newIri) {
                  (
                    ${datatypeChanged && changedProperty.datatype ? termToString(factory.namedNode(changedProperty.datatype)) : "UNDEF"}
                    ${classChanged && changedProperty.class ? termToString(factory.namedNode(changedProperty.class)) : "UNDEF"}
                    ${labelChanged ? termToString(factory.literal(changedProperty.label)) : "UNDEF"}
                    ${descriptionChanged && propertyDescription ? termToString(factory.literal(propertyDescription)) : "UNDEF"}
                    ${maxCountChanged && maxCount ? maxCount : "UNDEF"}
                    ${minCountChanged && minCount ? minCount : "UNDEF"}
                    ${orderChanged && changedProperty.order ? termToString(factory.literal(changedProperty.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : "UNDEF"}
                    ${groupChanged && changedProperty.group ? termToString(factory.namedNode(changedProperty.group.id)) : "UNDEF"}
                    ${
                      defaultValueChanged && defaultValue
                        ? termToString(
                            changedProperty.relationType === "datatype"
                              ? factory.literal(
                                  defaultValue,
                                  changedProperty.datatype ? factory.namedNode(changedProperty.datatype) : undefined,
                                )
                              : factory.namedNode(defaultValue),
                          )
                        : "UNDEF"
                    }
                    ${iriChanged && changedProperty.id ? termToString(factory.namedNode(changedProperty.id)) : "UNDEF"}
                  )
                }
              };
              delete {
                ?propertyShape sh:name ?label;
                              sh:description ?description;
                              sh:datatype ?datatype;
                              sh:class ?class;
                              sh:path ?iri;
                              sh:minCount ?minCount;
                              sh:maxCount ?maxCount;
                              sh:defaultValue ?defaultValue;
                              sh:order ?order;
                              sh:group ?group.
              } where {
                bind(${termToString(factory.namedNode(propertyInfo.propertyShape))} as ?propertyShape)
                values (?datatype ?class ?label ?description ?maxCount ?minCount ?order ?group ?defaultValue ?iri) {
                  (
                    ${datatypeChanged && initialValues.datatype ? termToString(factory.namedNode(initialValues.datatype)) : "UNDEF"}
                    ${classChanged && initialValues.class ? termToString(factory.namedNode(initialValues.class)) : "UNDEF"}
                    ${labelChanged ? termToString(factory.literal(initialValues.label)) : "UNDEF"}
                    ${descriptionChanged && initialValues.description ? termToString(factory.literal(initialValues.description)) : "UNDEF"}
                    ${maxCountChanged && initialValues.maxCount ? initialValues.maxCount : "UNDEF"}
                    ${minCountChanged && initialValues.minCount ? initialValues.minCount : "UNDEF"}
                    ${orderChanged && initialValues.order ? termToString(factory.literal(initialValues.order, factory.namedNode("http://www.w3.org/2001/XMLSchema#decimal"))) : "UNDEF"}
                    ${groupChanged && initialValues.group ? termToString(factory.namedNode(initialValues.group.id)) : "UNDEF"}
                    ${defaultValueChanged && initialValues.defaultValue ? termToString(initialValues.relationType === "datatype" ? factory.literal(initialValues.defaultValue, initialValues.datatype ? factory.namedNode(initialValues.datatype) : undefined) : factory.namedNode(initialValues.defaultValue)) : "UNDEF"}
                    ${iriChanged && changedProperty.id ? termToString(factory.namedNode(initialValues.id)) : "UNDEF"}
                  )
                }
              };
              `;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              setOpen(false);

              await dispatch<typeof refreshDatasetsInfo>(
                refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
              );
              await dispatch<typeof getGraphs>(
                getGraphs({
                  accountName: currentDs.owner.accountName,
                  datasetName: currentDs.name,
                  datasetId: currentDs.id,
                }),
              );
              await dispatch<typeof clearClassDescriptions>(clearClassDescriptions(currentDs.id));
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default EditProperty;
