import { useSparqlUpdateEnabled } from "#helpers/hooks/useSparqlUpdateEnabled.ts";
import { Chip } from "@mui/material";
import type { QueryServiceType } from "@triply/utils/Models";
import * as React from "react";

const ServiceProtocol: React.FC<{
  serviceType: QueryServiceType;
}> = ({ serviceType }) => {
  const isSparqlUpdateEnabled = useSparqlUpdateEnabled();
  if (serviceType === "speedy")
    return (
      <>
        <Chip label={"SPARQL"} variant="outlined" color="secondary" className="mr-1" size="small" />{" "}
        {isSparqlUpdateEnabled && <Chip label={"SPARQL Update"} variant="outlined" color="primary" size="small" />}
      </>
    );
  if (serviceType === "elasticSearch")
    return <Chip label={"Elasticsearch"} variant="outlined" color="success" size="small" />;
  if (serviceType === "virtuoso" || serviceType === "blazegraph" || serviceType === "jena")
    return <Chip label={"SPARQL"} variant="outlined" color="secondary" size="small" />;
  return null;
};

export default ServiceProtocol;
