import { NTriplyStatementToQuad, quadToNtriply } from "@core/utils/nTriply";
import { iriValidations, toStringValidator } from "@core/utils/validation";
import { Alert } from "@mui/material";
import { filter, uniq, uniqBy } from "lodash-es";
import * as React from "react";
import type { NtriplyStatements, PrefixInfo } from "@triply/utils/Models";
import { getPrefixed, getPrefixInfoFromIri } from "@triply/utils/prefixUtils";
import { factories, parse } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import { useDatasetPrefixes } from "#helpers/hooks/useDatasetPrefixes.ts";
import { Button, ConfirmationDialog, Dialog, FontAwesomeIcon, LoadingButton } from "../../../components";
import useConstructUrlToApi from "../../../helpers/hooks/useConstructUrlToApi";
import useDispatch from "../../../helpers/hooks/useDispatch";
import { refreshDatasetsInfo, useCurrentDataset } from "../../../reducers/datasetManagement";
import { getGraphs } from "../../../reducers/graphs";
import type { Query } from "..";
import QuadEditor from "../QuadEditor/index";
import { printQuadStatements } from "./utils";
import * as styles from "../style.scss";

const factory = factories.compliant;

const AddStatements: React.FC<{
  query: Query;
}> = ({ query }) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const iriValidator = toStringValidator(iriValidations);

  const [quadsToAdd, setQuadsToAdd] = React.useState<NtriplyStatements>([]);
  const [error, setError] = React.useState<string | undefined>();
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
    fromBrowser: true,
  });
  const consoleUrl = useConstructConsoleUrl()();
  const dispatch = useDispatch();
  const prefixes = useDatasetPrefixes();

  const isPrefilled = query.subject || query.predicate || query.object || query.graph;
  const [isDirty, setDirty] = React.useState(isPrefilled ? true : false);

  const onClose = () => {
    if (isDirty) {
      setConfirmationDialogOpen(true);
    } else {
      setOpen(false);
    }
  };

  const objectDatatypeRegEx = /^("(.|\n)*"\^\^)/;

  const subjectPrefixInfo = query.subject ? getPrefixInfoFromIri(query.subject, prefixes) : undefined;
  const predicatePrefixInfo = query.predicate ? getPrefixInfoFromIri(query.predicate, prefixes) : undefined;
  const isObjectIri = iriValidator(query.object)?.includes("is not a valid IRI") ? false : true;
  const objectPrefixInfo = query.object && isObjectIri ? getPrefixInfoFromIri(query.object, prefixes) : undefined;
  const graphPrefixInfo = query.graph ? getPrefixInfoFromIri(query.graph, prefixes) : undefined;

  let prefixFilter = ["graph", "rdf", "rdfs"]; // Default prefixes to load in query string
  // Adding subject, predicate, object & graph prefixes to prefix filter list
  if (subjectPrefixInfo && subjectPrefixInfo.prefixLabel) prefixFilter.push(subjectPrefixInfo.prefixLabel);
  if (predicatePrefixInfo && predicatePrefixInfo.prefixLabel) prefixFilter.push(predicatePrefixInfo.prefixLabel);
  if (objectPrefixInfo && objectPrefixInfo.prefixLabel) prefixFilter.push(objectPrefixInfo.prefixLabel);
  if (graphPrefixInfo && graphPrefixInfo.prefixLabel) prefixFilter.push(graphPrefixInfo.prefixLabel);

  const templateSubject = query.subject ? getPrefixedIri(query.subject, subjectPrefixInfo) : "<subject>";
  const templatePredicate = query.predicate ? getPrefixedIri(query.predicate, predicatePrefixInfo) : "<predicate>";
  const templateObject = query.object
    ? isObjectIri
      ? getPrefixedIri(query.object, objectPrefixInfo)
      : objectDatatypeRegEx.test(query.object)
        ? processObjectDatatype(query.object)
        : query.object
    : "<object>";
  const templateGraph = query.graph ? getPrefixedIri(query.graph, graphPrefixInfo) : "graph:default";

  function getPrefixedIri(iri: string, prefixInfo?: PrefixInfo) {
    if (prefixInfo && prefixInfo.prefixLabel) return getPrefixed(iri, prefixes);
    else return `<${iri}>`;
  }

  function processObjectDatatype(object: string) {
    const datatypeIri = `${object.replace(objectDatatypeRegEx, "")}`;
    const dataTypeIriPrefixInfo = getPrefixInfoFromIri(datatypeIri, prefixes);
    if (dataTypeIriPrefixInfo && dataTypeIriPrefixInfo.prefixLabel)
      prefixFilter.push(dataTypeIriPrefixInfo.prefixLabel);
    const prefixedDatatype = getPrefixedIri(datatypeIri, dataTypeIriPrefixInfo);
    return object.replace(/\^\^(.*)/, `^^${prefixedDatatype}`);
  }

  // Removing duplicate prefixes
  prefixFilter = uniq(prefixFilter);

  const _prefixes = filter(prefixes, (prefix) => prefixFilter.includes(prefix.prefixLabel)); //Getting only needed prefixes from dataset + global prefixes
  const uniquePrefixes = uniqBy(_prefixes, (prefix) => prefix.iri);

  const startingTemplate = `${uniquePrefixes.map((prefix) => `@prefix ${prefix.prefixLabel}: ${termToString(factory.namedNode(prefix.iri))}.`).join("\n")}

${templateGraph} {
  ${templateSubject} ${templatePredicate} ${templateObject}
}
  `;

  async function submitChanges() {
    setSaving(true);

    const _quadsToAdd = quadsToAdd.map((tquad) => NTriplyStatementToQuad(tquad));
    const valuesToAdd = printQuadStatements(_quadsToAdd);

    const query = `
    insert {
    ${valuesToAdd}
    } where {}
    `;

    const body = new FormData();
    body.set("update", query);

    await fetch(updateUrl, {
      credentials: "same-origin",
      method: "POST",
      body: body,
    });

    await dispatch<typeof refreshDatasetsInfo>(
      refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
    );
    await dispatch<typeof getGraphs>(
      getGraphs({
        accountName: currentDs.owner.accountName,
        datasetName: currentDs.name,
        datasetId: currentDs.id,
      }),
    );

    setSaving(false);
    setOpen(false);
  }

  function parseTriplesString(statementsString: string) {
    try {
      const n3Statements = parse(statementsString, {
        baseIri: `${consoleUrl}/${currentDs.owner.accountName}/${currentDs.name}`,
      });
      const updatedQuads = n3Statements.map((n3) => quadToNtriply(n3));
      setQuadsToAdd(updatedQuads);
      setError(undefined);
    } catch (e: any) {
      setError(e.message);
    }
  }

  React.useEffect(() => {
    if (isPrefilled) parseTriplesString(startingTemplate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        color="primary"
        elevation
        onClick={() => setOpen(true)}
        title={`Add statements`}
        startIcon={<FontAwesomeIcon icon="plus" />}
        size="small"
        className="m-2 mt-5 "
      >
        {`Add statements`}
      </Button>
      {confirmationDialogOpen && (
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onConfirm={() => {
            setConfirmationDialogOpen(false);
            setOpen(false);
          }}
          onClose={() => setConfirmationDialogOpen(false)}
          title="Are sure you want to close this form?"
          actionLabel="Close"
          description="If you close the form now, all changes will be lost."
        />
      )}

      {open && (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="lg"
          fullWidth
          title={`Add statements`}
          closeButton
          disableEscapeKeyDown
        >
          <div className="px-5 pb-5">
            <div className={styles.quadEditorContainer}>
              <QuadEditor
                className={styles.quadEditor}
                serializedString={startingTemplate}
                prefixes={prefixes}
                onChange={(values) => {
                  if (values.updatedString !== startingTemplate) {
                    setDirty(true);
                  } else {
                    if (isPrefilled) setDirty(true);
                    else setDirty(false);
                  }
                  if (values.updatedString) parseTriplesString(values.updatedString);
                }}
              />
            </div>
            {error && <Alert severity="error">{error}</Alert>}
            <div className="flex">
              <LoadingButton
                onClick={submitChanges}
                className="mt-2"
                type="submit"
                color="secondary"
                elevation
                disabled={!isDirty || error !== undefined || quadsToAdd.length < 1}
                loading={saving}
                aria-label="Submit"
              >
                {!isDirty || quadsToAdd.length < 1
                  ? "No changes"
                  : error
                    ? "Invalid"
                    : `Add ${quadsToAdd.length} statement(s)`}
              </LoadingButton>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default AddStatements;
