import * as React from "react";
import { useCachedSparql } from "../useCachedSparql";
import { allHierarchyPropertiesQuery, allSchemesQuery, conceptSchemeHierarchy } from "./queries";
import type { Scheme } from "./SchemeSelector";

interface Hierarchy {
  parentConceptScheme: string;
  childConceptScheme: string;
}

export interface HierarchyProperty {
  property: string;
  direction: "broader" | "narrower";
  withinScheme: "true" | "false";
}

interface SkosTreeContext {
  schemes: Scheme[] | undefined;
  hierarchy: Hierarchy[];
  hierarchyProperties?: HierarchyProperty[];
  loading: boolean;
}

export const SkosTreeContext = React.createContext<SkosTreeContext>({
  schemes: undefined,
  hierarchy: [],
  hierarchyProperties: undefined,
  loading: false,
});

const SkosTreeContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { data: schemes = undefined, loading: schemesLoading } = useCachedSparql<Scheme[] | undefined>(
    allSchemesQuery(),
  );
  const { data: hierarchy = [], loading: hierarchyLoading } = useCachedSparql<Hierarchy[]>(conceptSchemeHierarchy());
  const { data: hierarchyProperties, loading: hierarchyPropertiesLoading } =
    useCachedSparql<HierarchyProperty[]>(allHierarchyPropertiesQuery());

  const loading = schemesLoading || hierarchyLoading || hierarchyPropertiesLoading;

  const value = React.useMemo(() => {
    return {
      schemes,
      hierarchy,
      hierarchyProperties,
      loading,
    };
  }, [schemes, hierarchy, hierarchyProperties, loading]);

  return <SkosTreeContext value={value}>{children}</SkosTreeContext>;
};

export default SkosTreeContextProvider;
