import { syntaxTree } from "@codemirror/language";
import type { EditorState } from "@codemirror/state";
import { StateField } from "@codemirror/state";
import Debug from "debug";
import type { QueryType } from "#components/Sparql/SparqlUtils.ts";
import { QueryUnit, Update, Update1, UpdateUnit } from "../grammar/sparqlParser.terms";

const debug = Debug("triply:sparql-editor:fields:queryType");

export const queryTypeField = StateField.define<QueryType>({
  create: (state) => {
    return getQueryType(state) || "query";
  },
  update: (value, transaction) => {
    return getQueryType(transaction.state) || value;
  },
});

function getQueryType(state: EditorState) {
  debug("Running");
  const tree = syntaxTree(state);
  if (tree.topNode.getChild(QueryUnit)) {
    return "query";
  }
  if (tree.topNode.getChild(UpdateUnit)?.getChild(Update)?.getChild(Update1)) {
    return "update";
  }
}
