// extracted by mini-css-extract-plugin
var _1 = "styles--account--ort9__CG";
var _2 = "styles--accountName--iMSNoAa9";
var _3 = "styles--accounts--yOMAfct6";
var _4 = "styles--autoResize--WImmC363";
var _5 = "styles--autoResizeContainer--bAYLnOyS";
var _6 = "styles--autoResizeNoBg--_bEaMTQc";
var _7 = "styles--autoResizeWhiteBg--yfTbVZ4k";
var _8 = "styles--badge--xJHfbWmp";
var _9 = "styles--dragContainer--sBrJewPV";
var _a = "styles--dragging--_KMvv2C7";
var _b = "styles--editor--nv3qirlH";
var _c = "styles--editorContainer--SFHkCLgJ";
var _d = "styles--endpointSelector--Xbfep6l_";
var _e = "styles--executeQueryButton--ZjkEW_rW";
var _f = "styles--executeSparqlButton--yvPlguRu";
var _10 = "styles--executeUpdateButton--HDyPwBN6";
var _11 = "styles--icon--z6tgv5jn";
var _12 = "styles--ideWrapper--X6iOeVsj";
var _13 = "styles--largeResultsContainer--peD_AfAk";
var _14 = "styles--loading--O31LA9FH";
var _15 = "styles--loadingBar--MyOUrNri";
var _16 = "styles--playIcon--uSJmDVZU";
var _17 = "styles--resizableEditor--W1dD46rY";
var _18 = "styles--tab--z3GSCCDM";
var _19 = "styles--tabList--a594ysPy";
export { _1 as "account", _2 as "accountName", _3 as "accounts", _4 as "autoResize", _5 as "autoResizeContainer", _6 as "autoResizeNoBg", _7 as "autoResizeWhiteBg", _8 as "badge", _9 as "dragContainer", _a as "dragging", _b as "editor", _c as "editorContainer", _d as "endpointSelector", _e as "executeQueryButton", _f as "executeSparqlButton", _10 as "executeUpdateButton", _11 as "icon", _12 as "ideWrapper", _13 as "largeResultsContainer", _14 as "loading", _15 as "loadingBar", _16 as "playIcon", _17 as "resizableEditor", _18 as "tab", _19 as "tabList" }
