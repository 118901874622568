/**
 * DOCUMENTATION:
 *
 * To add an action:
 * - Add your action to the `Actions` interface. The key is the action name, and the value is the context for that action
 * (i.e., extra variables you need for the acl check)
 * - Add a check for your new action to one (or more) roles in the `acl` variable
 */

import type { Merge } from "ts-essentials";
import type { Models } from "@triply/utils";
import type { AclDefinition } from "./utils.ts";
import { AclError } from "./utils.ts";

export const tokenTypes = ["web", "read", "writeDs", "writeAccount", undefined] as const;
export type TokenType = (typeof tokenTypes)[number];

export interface CommonActionContext {
  tokenType: TokenType; //web token, or custom token permissions
  adminTokensAllowed: boolean;
}

const rolesInAccount = ["owner", "member", undefined] as const;
type RoleInAccount = (typeof rolesInAccount)[number];
// prettier-ignore
export interface Actions {
  /**
   * Account-related actions
   */
  editAccountMetadata: Merge<
    {
      roleInAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageOrganizationMembers: Merge<
    {
      roleInOrganization: RoleInAccount;
    },
    CommonActionContext
  >;
  deleteAccount: Merge<
    {
      roleInAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageTokens: Merge<
    {
      roleInUser: RoleInAccount;
    },
    CommonActionContext
  >;
  createOrganization: Merge<
    {
      roleInUser: RoleInAccount;
    },
    CommonActionContext
  >;
  manageMfa: Merge<
    {
      isOwnAccount: boolean;
    },
    CommonActionContext
  >;
  disableMfa: Merge<
    {
      isOwnAccount: boolean;
    },
    CommonActionContext
  >;

  /**
   * Dataset-related actions
   */
  readDataset: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  importDataToDataset: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  createDataset: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  deleteDataset: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  editDatasetMetadata: Merge<
    {
      newAccessLevel: Models.AccessLevel | undefined;
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageAssets: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageServices: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageGraphs: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageDataModel: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  canSeeCreateServiceButton:
  {
    roleInOwnerAccount: RoleInAccount;
  };


  /**
   * Query and story-related actions
   */
  createQuery: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  manageQuery: Merge<
    {
      newAccessLevel: Models.AccessLevel | undefined;
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  readQuery: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  runPipeline: Merge<
  {
    roleInOwnerAccount: RoleInAccount;
  },
  CommonActionContext
  >;
  cancelPipeline: Merge<
    {
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  readPipeline: Merge<
  {
    roleInOwnerAccount: RoleInAccount;
  },
  CommonActionContext
>;
  manageStory: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  readStory: Merge<
    {
      accessLevel: Models.AccessLevel;
      roleInOwnerAccount: RoleInAccount;
    },
    CommonActionContext
  >;
  copyQuery: Merge<
  {
    accessLevel: Models.AccessLevel;
    roleInOwnerAccount: RoleInAccount;
  },
  CommonActionContext
  >;
  copyDataset: Merge<
  {
    accessLevel: Models.AccessLevel;
    roleInOwnerAccount: RoleInAccount;
  },
  CommonActionContext
  >;

  /**
   * Admin related actions
   */

  changeUserRole: Merge<{ toRole: Role | undefined; fromRole: Role | undefined }, CommonActionContext>;
  uploadHdt: CommonActionContext;
  editInstanceMetadata: CommonActionContext;
  exceedPaginationLimit: CommonActionContext;
  impersonateUser: CommonActionContext;
  killServiceWhenStarting: CommonActionContext;
  listServicesOfInstance: CommonActionContext;
  manageBackgroundTasks: CommonActionContext;
  manageSpeedy: CommonActionContext;
  useQueryJobUi: CommonActionContext;
  manageGlobalPrefixes: CommonActionContext;
  manageInstanceCache: CommonActionContext;
  manageRedirects: CommonActionContext;
  manageTopics: CommonActionContext;
  manageUsers: CommonActionContext;
  manageDatasets: CommonActionContext;
  manageQueries: CommonActionContext;
  manageQueryJobs: CommonActionContext;
  manageSamlConfiguration: CommonActionContext;
  readInstanceLimits: CommonActionContext;
  readJobsOnInstance: CommonActionContext;
  readServerError: CommonActionContext;
  readLicenseExpireDate: CommonActionContext;
  readStartupInfo: CommonActionContext;
  setNewPasswordWithoutCurrentPassword: Merge<
    {
      changingSelf: boolean;
    },
    CommonActionContext
  >;
  showServiceDebugInformation: CommonActionContext;
  setServiceMemoryLimit: CommonActionContext;
  viewSuperadminRole: CommonActionContext;
  stopOrStartService: CommonActionContext;
  stopServiceWithAutostart: CommonActionContext;
  updateServiceVersion: CommonActionContext;
  toggleDebugging: CommonActionContext;
  toggleInstanceFeatures: CommonActionContext;
  seeQueryDelayAndCacheInfo: CommonActionContext;
}
export type Role = Models.UserRole;

const adminAllow = ({ tokenType, adminTokensAllowed }: { tokenType: TokenType; adminTokensAllowed: boolean }) => {
  if (assertTokenType(tokenType, ["web"])) return true;
  return adminTokensAllowed && !!tokenType;
};

function assertTokenType(actualToken: TokenType, expectedTokens: Array<TokenType>) {
  if (!tokenTypes.includes(actualToken)) throw new AclError(`Unsupported token type '${actualToken}'`);
  return expectedTokens.includes(actualToken);
}
function assertRoleInAccount(actualRole: RoleInAccount, expectedRoles: Array<RoleInAccount>) {
  if (!rolesInAccount.includes(actualRole)) throw new AclError(`Unsupported account role '${actualRole}'`);
  return expectedRoles.includes(actualRole);
}

const acl: AclDefinition = {
  superAdmin: {
    grants: [
      { action: "changeUserRole", when: adminAllow },
      { action: "impersonateUser", when: adminAllow },
      { action: "killServiceWhenStarting", when: adminAllow },
      { action: "manageInstanceCache", when: adminAllow },
      { action: "manageTopics", when: adminAllow },
      { action: "readJobsOnInstance", when: adminAllow },
      { action: "setServiceMemoryLimit", when: adminAllow },
      { action: "showServiceDebugInformation", when: adminAllow },
      { action: "stopOrStartService", when: adminAllow },
      { action: "stopServiceWithAutostart", when: adminAllow },
      { action: "readServerError", when: adminAllow },
      { action: "toggleDebugging", when: adminAllow },
      { action: "toggleInstanceFeatures", when: adminAllow },
      { action: "uploadHdt", when: adminAllow },
      { action: "viewSuperadminRole", when: adminAllow },
      { action: "manageBackgroundTasks", when: adminAllow },
      { action: "manageSpeedy", when: adminAllow },
      { action: "seeQueryDelayAndCacheInfo", when: adminAllow },
      { action: "manageQueryJobs", when: adminAllow },
      { action: "manageSamlConfiguration", when: adminAllow },
    ],
    extends: ["siteAdmin"],
  },
  siteAdmin: {
    grants: [
      /**
       * Account-related actions
       */
      { action: "createOrganization", when: adminAllow },
      {
        action: "changeUserRole",
        when: (ctx) => {
          return adminAllow(ctx) && ctx.toRole !== "superAdmin" && ctx.fromRole !== "superAdmin";
        },
      },
      { action: "editAccountMetadata", when: adminAllow },
      { action: "manageOrganizationMembers", when: adminAllow },
      { action: "deleteAccount", when: adminAllow },
      { action: "manageTokens", when: adminAllow },
      { action: "disableMfa", when: adminAllow },

      /**
       * Dataset related
       */
      { action: "readDataset", when: adminAllow },
      { action: "createDataset", when: adminAllow },
      { action: "importDataToDataset", when: adminAllow },
      { action: "deleteDataset", when: adminAllow },
      { action: "manageAssets", when: adminAllow },
      { action: "editDatasetMetadata", when: adminAllow },
      { action: "manageGraphs", when: adminAllow },
      { action: "manageDataModel", when: adminAllow },
      { action: "manageServices", when: adminAllow },
      { action: "copyDataset", when: adminAllow },

      /**
       * Query and story-related actions
       */
      { action: "createQuery", when: adminAllow },
      { action: "manageQuery", when: adminAllow },
      { action: "readQuery", when: adminAllow },
      { action: "manageStory", when: adminAllow },
      { action: "readStory", when: adminAllow },
      { action: "copyQuery", when: adminAllow },

      /**
       * Query job-related actions
       */
      { action: "runPipeline", when: adminAllow },
      { action: "readPipeline", when: adminAllow },
      { action: "cancelPipeline", when: adminAllow },

      /**
       * Admin related actions
       */
      { action: "editInstanceMetadata", when: adminAllow },
      { action: "exceedPaginationLimit", when: adminAllow },
      { action: "listServicesOfInstance", when: adminAllow },
      { action: "manageGlobalPrefixes", when: adminAllow },
      { action: "manageDatasets", when: adminAllow },
      { action: "manageQueries", when: adminAllow },
      { action: "manageRedirects", when: adminAllow },
      { action: "manageUsers", when: adminAllow },
      { action: "readInstanceLimits", when: adminAllow },
      { action: "readLicenseExpireDate", when: adminAllow },
      { action: "readStartupInfo", when: adminAllow },
      { action: "updateServiceVersion", when: adminAllow },
      { action: "manageQueryJobs", when: adminAllow },
      {
        action: "setNewPasswordWithoutCurrentPassword",
        when: (ctx) => {
          return adminAllow(ctx) && !ctx.changingSelf;
        },
      },
    ],
    extends: ["regular"],
  },
  regular: {
    grants: [
      {
        action: "createOrganization",
        when: ({ tokenType, roleInUser }) =>
          assertRoleInAccount(roleInUser, ["owner"]) && assertTokenType(tokenType, ["web", "writeAccount"]),
      },
      {
        action: "manageOrganizationMembers",
        when: ({ tokenType, roleInOrganization }) => {
          return (
            assertRoleInAccount(roleInOrganization, ["owner"]) && assertTokenType(tokenType, ["web", "writeAccount"])
          );
        },
      },
      {
        action: "manageServices",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "createDataset",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "importDataToDataset",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "createQuery",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageQuery",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageStory",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "deleteDataset",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "editDatasetMetadata",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "runPipeline",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "readPipeline",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "cancelPipeline",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
    ],
    extends: ["light"],
  },
  light: {
    grants: [
      {
        action: "readDataset",
        //internal access level, or has 'a' role (member of owner) in dataset account
        when: ({ accessLevel, roleInOwnerAccount }) =>
          accessLevel === "internal" || assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
      {
        action: "canSeeCreateServiceButton",
        when: ({ roleInOwnerAccount }) => assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
      {
        action: "createDataset",
        when: ({ accessLevel, roleInOwnerAccount, tokenType }) =>
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "createQuery",
        when: ({ accessLevel, roleInOwnerAccount, tokenType }) =>
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageQuery",
        when: ({ newAccessLevel, accessLevel, roleInOwnerAccount, tokenType }) =>
          (newAccessLevel === "public" || newAccessLevel === undefined) &&
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageStory",
        when: ({ accessLevel, roleInOwnerAccount, tokenType }) =>
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "deleteDataset",
        when: ({ accessLevel, roleInOwnerAccount, tokenType }) =>
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "importDataToDataset",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageAssets",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageTokens",
        when: {
          roleInUser: "owner",
          tokenType: "web",
        },
      },
      {
        action: "editDatasetMetadata",
        when: ({ newAccessLevel, accessLevel, roleInOwnerAccount, tokenType }) =>
          (newAccessLevel === "public" || newAccessLevel === undefined) &&
          accessLevel === "public" &&
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageGraphs",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "manageDataModel",
        when: ({ roleInOwnerAccount, tokenType }) =>
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeDs", "writeAccount"]),
      },
      {
        action: "editAccountMetadata",
        when: ({ tokenType, roleInAccount }) =>
          assertRoleInAccount(roleInAccount, ["owner", "member"]) &&
          assertTokenType(tokenType, ["web", "writeAccount"]),
      },
      {
        action: "manageMfa",
        when: ({ tokenType, isOwnAccount }) => tokenType === "web" && isOwnAccount,
      },
      {
        action: "disableMfa",
        when: ({ tokenType, isOwnAccount }) => tokenType === "web" && isOwnAccount,
      },
      {
        action: "deleteAccount",
        when: ({ tokenType, roleInAccount }) =>
          roleInAccount === "owner" && assertTokenType(tokenType, ["web", "writeAccount"]),
      },
      {
        action: "readQuery",
        //internal access level, or has 'a' role (member of owner) in dataset account
        when: ({ accessLevel, roleInOwnerAccount }) =>
          accessLevel === "internal" || assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
      {
        action: "readStory",
        //internal access level, or has 'a' role (member of owner) in dataset account
        when: ({ accessLevel, roleInOwnerAccount }) =>
          accessLevel === "internal" || assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
      {
        action: "copyQuery",
        when: ({ accessLevel, roleInOwnerAccount }) =>
          accessLevel === "public" ||
          accessLevel === "internal" ||
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
      {
        action: "copyDataset",
        when: ({ accessLevel, roleInOwnerAccount }) =>
          accessLevel === "public" ||
          accessLevel === "internal" ||
          assertRoleInAccount(roleInOwnerAccount, ["owner", "member"]),
      },
    ],
    extends: ["none"],
  },
  none: {
    grants: [
      { action: "readDataset", when: { accessLevel: "public" } },
      { action: "readQuery", when: { accessLevel: "public" } },
      { action: "readStory", when: { accessLevel: "public" } },
    ],
  },
};
export default acl;
