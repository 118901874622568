import { flatMap, flatten, keyBy, uniq } from "lodash-es";
import type { ReactNode } from "react";
import { createContext } from "react";
import * as React from "react";
import useSparql from "#helpers/hooks/useSparql.ts";

export const ClassInfoContext = createContext<ClassInfo | undefined | null>(null);

type SearchProperty = { path: string; class?: string; paths?: string[][] };
type QueryResult = {
  class: string;
  hasInstances?: true;
  hasNodeShape?: true;
  descendants?: string[];
  searchProperties?: SearchProperty[];
  searchPaths?: string[][];
};
export type ClassInfo = { [rdfClass: string]: QueryResult };

const query = `
# Class info

prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#>
prefix sh: <http://www.w3.org/ns/shacl#>
prefix form: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

select ?class ?hasNodeShape ?hasInstances ?searchProperties_path ?searchProperties_class ?descendants where {
  {
    select ?class (sample(?_hasNodeShape) as ?hasNodeShape) (sample(?_hasInstances) as ?hasInstances) where {
      {
        [] sh:targetClass ?class
        bind(true as ?_hasNodeShape)
      } union {
        [] a ?class
        bind(true as ?_hasInstances)
      } union {
        [] rdfs:subClassOf|^rdfs:subClassOf ?class
      }
    }
    group by ?class
  }
  optional {
    ?class rdfs:subClassOf*/^sh:targetClass/sh:property ?propertyShape .
    ?propertyShape form:useInSearch true ;
    sh:path ?searchProperties_path .
    optional {
      ?propertyShape sh:class ?searchProperties_class
    }
  }
  optional {
    ?descendants rdfs:subClassOf+ ?class .
  }
}
`;

const defaultSearchProperties: SearchProperty[] = [
  { path: "http://www.w3.org/2000/01/rdf-schema#label" },
  { path: "http://www.w3.org/2004/02/skos/core#prefLabel" },
];

export const ClassInfoProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useSparql<QueryResult[]>(query, { singularizeVariables: { descendants: false } });

  const classInfo = React.useMemo(() => {
    if (!data) return;
    const classInfo = keyBy(data, "class");

    const getSearchPaths = (searchProperty: SearchProperty, depth = 0) => {
      if (searchProperty.class) {
        if (depth >= 3) return [];
        const subPaths: string[][] = flatten(
          (classInfo[searchProperty.class]?.searchProperties || defaultSearchProperties).map((p) =>
            getSearchPaths(p, depth + 1),
          ) || [],
        );
        return subPaths.map((p) => [searchProperty.path, ...p]);
      } else {
        return [[searchProperty.path]];
      }
    };

    for (const rdfClass in classInfo) {
      for (const searchProperty of classInfo[rdfClass].searchProperties || []) {
        searchProperty.paths = getSearchPaths(searchProperty);
      }

      classInfo[rdfClass].searchPaths = flatten(
        (classInfo[rdfClass].searchProperties || defaultSearchProperties).map((p) => p.paths || [[p.path]]),
      );
    }

    return classInfo;
  }, [data]);

  return <ClassInfoContext value={classInfo}>{children}</ClassInfoContext>;
};

export type SearchMeta = { propertyPaths: string[][]; rdfClass: string }[];
type GetSearchMeta = (opts: {
  classInfo: ClassInfo;
  rdfClasses?: string[];
  includeSubClasses?: boolean;
  hasNodeShape?: boolean;
}) => SearchMeta;

export const getSearchMeta: GetSearchMeta = ({ classInfo, rdfClasses, includeSubClasses, hasNodeShape }) => {
  if (!rdfClasses) {
    rdfClasses = Object.keys(classInfo);
  }

  if (includeSubClasses) {
    rdfClasses = uniq(flatMap(rdfClasses, (rdfClass) => [rdfClass, ...(classInfo[rdfClass]?.descendants || [])]));
  }

  rdfClasses = rdfClasses.filter((rdfClass) => !!classInfo[rdfClass]?.hasInstances);

  if (hasNodeShape) {
    rdfClasses = rdfClasses.filter((rdfClass) => !!classInfo[rdfClass]?.hasNodeShape);
  }

  return rdfClasses.map((rdfClass) => {
    return {
      rdfClass: rdfClass,
      propertyPaths: classInfo[rdfClass]?.searchPaths || defaultSearchProperties.map((p) => [p.path]),
    };
  });
};
