import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import type { ButtonProps } from "@mui/material";
import { Box, Container, TextField, Tooltip } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { factories } from "@triplydb/data-factory";
import { termToString } from "@triplydb/sparql-ast/serialize";
import { Button, Dialog, FontAwesomeIcon, LoadingButton } from "#components/index.ts";
import fetch from "#helpers/fetch.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useDispatch from "#helpers/hooks/useDispatch.ts";
import { parseSearchString } from "#helpers/utils.ts";
import { useAuthenticatedUser } from "#reducers/auth.ts";
import { refreshDatasetsInfo, useCurrentDataset } from "#reducers/datasetManagement.ts";
import { getGraphs } from "#reducers/graphs.ts";
import { getDescription } from "#reducers/resourceEditorDescriptions.ts";

const factory = factories.compliant;
interface Props {
  buttonLabel: string;
  title: string;
  icon?: FontAwesomeIconProps["icon"];
  confirmationLabel: string;
  newStatus: string;
  color?: ButtonProps["color"];
  disabled?: string;
  fromStatus: string;
}

export const ResourceAction: React.FC<Props> = ({
  buttonLabel,
  title,
  confirmationLabel,
  newStatus,
  color,
  icon,
  disabled,
  fromStatus,
}) => {
  const [open, setOpen] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const currentDs = useCurrentDataset()!;
  const updateUrl = useConstructUrlToApi()({
    pathname: `/datasets/${currentDs.owner.accountName}/${currentDs.name}/sparql`,
    fromBrowser: true,
  });
  const dispatch = useDispatch();
  const resource = parseSearchString(useLocation().search).resource as string;
  const consoleUrl = useConstructConsoleUrl()();
  const authorAcc = useAuthenticatedUser();
  const authorUrl = `${consoleUrl}/${authorAcc?.accountName}`;
  const datasetPath = `${currentDs.owner.accountName}/${currentDs.name}`;
  const historyIri = factory.namedNode(`${consoleUrl}/${datasetPath}/history/r-${uuid()}`);

  return (
    <>
      <Tooltip title={disabled}>
        <div>
          <Button
            color={color}
            elevation
            onClick={() => setOpen(true)}
            title={title}
            disabled={!!disabled}
            startIcon={icon && <FontAwesomeIcon icon={icon} />}
            size="small"
          >
            {buttonLabel}
          </Button>
        </div>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth title={title} closeButton>
        <Container className="pb-3">
          <p>{title} the resource with the following comment:</p>
          <form
            className="px-5 pb-5"
            onSubmit={async (e) => {
              e.preventDefault();
              setSaving(true);

              const textField = e.currentTarget[0] as HTMLTextAreaElement;

              const query = `
                prefix skos: <http://www.w3.org/2004/02/skos/core#>
                prefix meta: <https://triplydb.com/Triply/TriplyDB-instance-editor-vocabulary/>

                insert {
                  ?historyObject a meta:Event;
                    meta:time ?editedAt;
                    meta:product ?editedValue;
                    meta:actor ?editor;
                    meta:toStatus ?newStatus;
                    meta:fromStatus ?oldStatus;
                    meta:action ?takenAction;
                    meta:editorialNote ?note.
                } where {
                  bind(<${resource}> as ?editedValue)
                  bind(now() as ?editedAt)
                  bind(${termToString(historyIri)} as ?historyObject)
                  bind(${termToString(factory.namedNode(authorUrl))} as ?editor)
                  values( ?newStatus ?oldStatus ?takenAction) {
                    (${newStatus !== "unknown" ? termToString(factory.literal(newStatus)) : "undef"} ${fromStatus !== "unknown" ? termToString(factory.literal(fromStatus)) : "undef"} ${termToString(factory.literal(title))})
                  }
                  optional {
                    values ?note {
                      ${termToString(factory.literal(textField.value.trim() || ""))}
                    }
                  }

                }`;

              const body = new FormData();
              body.set("update", query);

              await fetch(updateUrl, {
                credentials: "same-origin",
                method: "POST",
                body: body,
              });

              await Promise.all([
                dispatch<typeof refreshDatasetsInfo>(
                  refreshDatasetsInfo({ accountName: currentDs.owner.accountName, datasetName: currentDs.name }),
                ),
                dispatch<typeof getGraphs>(
                  getGraphs({
                    accountName: currentDs.owner.accountName,
                    datasetName: currentDs.name,
                    datasetId: currentDs.id,
                  }),
                ),
                dispatch<typeof getDescription>(getDescription({ dataset: currentDs, resource: resource })),
              ]);

              setSaving(false);
              setOpen(false);
            }}
          >
            <TextField label="Comment (optional)" multiline minRows={3} disabled={saving} fullWidth variant="filled" />
            <Box className="mt-3">
              <Button variant="text" onClick={() => setOpen(false)} disabled={saving}>
                Cancel
              </Button>
              <LoadingButton color={color} type="submit" loading={saving} disabled={saving} variant="contained">
                {title}
              </LoadingButton>
            </Box>
          </form>
        </Container>
      </Dialog>
    </>
  );
};

export default ResourceAction;
