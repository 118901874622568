import type { ColorOptions } from "@marko19907/string-to-color";
import { Xorwow } from "@marko19907/string-to-color";
import { Alert, Box, IconButton, LinearProgress, Paper } from "@mui/material";
import type { Virtualizer } from "@tanstack/react-virtual";
import { useVirtualizer } from "@tanstack/react-virtual";
import getClassName from "classnames";
import debug from "debug";
import * as React from "react";
import useResizeObserver from "use-resize-observer";
import { FontAwesomeIcon } from "#components/index.ts";
import useConstructUrlToApi from "#helpers/hooks/useConstructUrlToApi.ts";
import useCurrentResource from "#helpers/hooks/useCurrentResource.ts";
import useCurrentSearch from "#helpers/hooks/useCurrentSearch.ts";
import useDebounce from "#helpers/hooks/useDebounce.ts";
import useHideFooter from "#helpers/hooks/useHideFooter.ts";
import { useCurrentDataset } from "#reducers/datasetManagement.ts";
import { ExpandedContext } from "./tree/ExpandedContext";
import { sparqlFetchExpandable, sparqlFetchTopLevelConcepts } from "./tree/queries";
import SchemeSelector from "./tree/SchemeSelector";
import type { HierarchyProperty } from "./tree/SkosTreeContext";
import { SkosTreeContext } from "./tree/SkosTreeContext";
import TreeTopItem from "./tree/TreeTopItem";
import { SearchField } from "./SearchField";
import { useCachedSparql } from "./useCachedSparql";
import * as styles from "./style.scss";

const log = debug("triply:skos:active-scroll");

// Picked a different algorithm here so that the colors are a bit further apart
export const COLOR_GENERATE_OPTIONS: ColorOptions = { lightness: 90, algorithm: Xorwow };

export type SparqlTermResult = {
  concept: string;
  conceptLabel: string;
  scheme: string;
  isExpandable?: string;
};

export type SparqlSchemeResult = {
  scheme: string;
  schemeTitle: string;
};

export type SkosTreeProps = {
  className: string;
};

const SkosTree: React.FC<SkosTreeProps> = ({ className }) => {
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const selectedSchemes = conceptSchemesQueryString.split(",").filter(Boolean);
  const { schemes, loading: globalLoading, hierarchyProperties } = React.useContext(SkosTreeContext);
  const expandableCache = React.useRef<Map<string, boolean>>(new Map());
  const scrollWrapperRef = React.useRef<HTMLDivElement>(null);
  const { activeMap, collapseAll } = React.useContext(ExpandedContext);
  const wrapper = React.useRef<HTMLDivElement>(null);
  const resource = useCurrentResource();
  const intersectionObserverRef = React.useRef<IntersectionObserver>(undefined);
  useHideFooter();
  const { data: topLevelConcepts, loading: dataLoading } = useCachedSparql<SparqlTermResult[]>(
    !schemes || schemes.length > 0
      ? sparqlFetchTopLevelConcepts({ useSchemes: true, topLevelScheme: selectedSchemes[0] })
      : hierarchyProperties
        ? sparqlFetchTopLevelConcepts({ useSchemes: false, hierarchyProperties })
        : "",
  );
  const rowVirtualizer = useVirtualizer({
    count: topLevelConcepts?.length ?? 0,
    getScrollElement: () => scrollWrapperRef.current,
    estimateSize: () => 38,
    overscan: 40,
  });

  const { ref: resizeObserverRef, height: filtersHeight = 150 } = useResizeObserver();
  const { ref: parentRef, height: allHeight = 350 } = useResizeObserver();

  React.useEffect(() => {
    const items = Array.from(scrollWrapperRef.current?.children?.[0]?.children ?? []) as HTMLDivElement[];
    if (items) {
      for (const item of Array.from(items)) {
        item.style.height = "auto";
        rowVirtualizer.measureElement(item);
      }
    }
  }, [rowVirtualizer]);

  React.useEffect(() => {
    const firstActive = Object.keys(activeMap[conceptSchemesQueryString] ?? {})?.[0];
    if (firstActive) {
      const activeIndex = topLevelConcepts?.findIndex((item) => item.concept === firstActive);
      if (activeIndex === -1 || typeof activeIndex !== "number") return;

      // Our first point for the scroll to active functionality. This is potentially complex to debug. Dealing with scroll can be difficult.
      // This only happens once for each active resource when the parent is in view
      log("use effect");

      const innerTree = wrapper.current?.querySelector<HTMLDivElement>('[class*="innerTree"]');

      if (innerTree) {
        const innerTreeChild = innerTree.querySelector('[class*="innerTreeChild"]');

        // We have a fallback so we can escape the interval if something goes wrong.
        let iterations = 0;

        const activeItem = innerTree.querySelector(`[class*="treeItemActive"][data-concept-iri="${resource}"]`);
        if (activeItem) {
          intersectionObserverRef.current = new IntersectionObserver(
            (entries) => {
              if (!entries[0].isIntersecting) {
                log("Quick scroll, no interval needed");
                activeItem.scrollIntoView({ behavior: "smooth", block: "center" });
              }
              intersectionObserverRef.current?.disconnect();
              intersectionObserverRef.current = undefined;
            },
            { threshold: 1.0 },
          );
          intersectionObserverRef.current.observe(activeItem);
        } else {
          log("scrolled to parent via react-virtual because active item was not in DOM");
          // we do not use { behavior: smooth } here because the library implementation is sometimes buggy and creates flickering.
          // This will ultimately open up the active resource. This will happen in a small waterfall of requests.
          rowVirtualizer.scrollToIndex(activeIndex, { align: "start" });

          const interval = setInterval(() => {
            iterations++;
            // The actual active item we want. Because of data fetching just .treeItemActive would match the old active item.
            const activeItem = innerTree.querySelector(`[class*="treeItemActive"][data-concept-iri="${resource}"]`);
            log("in interval, waiting for waterfall to finish");

            if (iterations > 10 && (innerTreeChild?.clientHeight ?? 0) < innerTree.clientHeight) {
              clearInterval(interval);
              log("clearing because there is no scroll");
            }

            // We do not want faulty intervals running
            if (iterations > 50) {
              clearInterval(interval);
              log("clearing without success");
            }

            if (activeItem && !rowVirtualizer.isScrolling) {
              clearInterval(interval);
              log("cleared interval");
              setTimeout(() => {
                log("scrolled smoothly to active");
                activeItem.scrollIntoView({ behavior: "smooth", block: "center" });
              }, 200);
            }
          }, 200);
        }
      } else {
        log("We should never be here");
      }
    }
    // If we add resource here, we can many triggers and it is hard to deduplicate them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptSchemesQueryString, activeMap, topLevelConcepts, dataLoading, rowVirtualizer]);

  return (
    <Paper className={getClassName(className, "p-3", "noShrink")} ref={parentRef}>
      <Box ref={resizeObserverRef}>
        {schemes && schemes.length > 0 && <SchemeSelector />}

        <SearchField />

        <div className="flex horizontalEnd mt-4">
          <IconButton size="small" onClick={collapseAll} title="Collapse all" aria-label="Collapse all">
            <FontAwesomeIcon icon={"arrows-to-line"} />
          </IconButton>
        </div>

        <Box className={getClassName("flex", styles.progressBar)}>
          {(!topLevelConcepts || topLevelConcepts.length === 0) && dataLoading && (
            <LinearProgress color="primary" className={getClassName(styles.treeLoading, "my-2 flex grow")} />
          )}
        </Box>

        {!selectedSchemes.length && topLevelConcepts?.length === 0 && (
          <Alert severity="info">{`${!globalLoading && schemes && schemes.length === 0 ? "No SKOS concept scheme detected" : "Start by selecting a concept scheme"}`}</Alert>
        )}
      </Box>

      <div className="flex column grow" ref={wrapper}>
        {topLevelConcepts && topLevelConcepts.length ? (
          <div ref={scrollWrapperRef} className={styles.innerTree} style={{ height: allHeight - filtersHeight }}>
            <InnerTree
              rowVirtualizer={rowVirtualizer}
              topLevelConcepts={topLevelConcepts}
              expandableCache={expandableCache.current}
            />
          </div>
        ) : null}
      </div>
    </Paper>
  );
};

export default SkosTree;

type InnerTreeProps = {
  topLevelConcepts: SparqlTermResult[];
  rowVirtualizer: Virtualizer<HTMLDivElement, Element>;
  expandableCache: Map<string, boolean>;
};

const InnerTree: React.FC<InnerTreeProps> = ({ topLevelConcepts, expandableCache, rowVirtualizer }) => {
  const dataset = useCurrentDataset();
  const sparqlUrl = useConstructUrlToApi()({
    pathname: `/_console/sparql`,
    fromBrowser: true,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();
  const terms = React.useMemo(
    () => virtualItems.map((virtualItem) => topLevelConcepts[virtualItem.index]),
    [topLevelConcepts, virtualItems],
  );
  const [dataExpandable, setDataExpandable] = React.useState<Map<string, boolean>>(new Map(expandableCache.entries()));
  const search = useCurrentSearch();
  const conceptSchemesQueryString = (search.conceptScheme as string) ?? "";
  const selectedSchemes = React.useMemo(
    () => conceptSchemesQueryString.split(",").filter(Boolean),
    [conceptSchemesQueryString],
  );

  const { schemes, hierarchyProperties } = React.useContext(SkosTreeContext);

  const fetchExpandable = useDebounce(
    (
      terms: SparqlTermResult[],
      selectedSchemes: string[],
      conceptSchemesQueryString: string,
      hierarchyProperties: HierarchyProperty[] | undefined,
    ) => {
      if (!dataset) return;

      const uncachedTerms = terms.filter(
        (term) => !expandableCache.has(conceptSchemesQueryString + ":" + term.concept),
      );
      const cachedTerms = terms.filter((term) => expandableCache.has(conceptSchemesQueryString + ":" + term.concept));
      const firstLocalMap: Map<string, boolean> = new Map();

      for (const resultItem of cachedTerms) {
        if (expandableCache.has(conceptSchemesQueryString + ":" + resultItem.concept)) {
          firstLocalMap.set(
            conceptSchemesQueryString + ":" + resultItem.concept,
            expandableCache.get(conceptSchemesQueryString + ":" + resultItem.concept)!,
          );
        }
      }

      setDataExpandable(firstLocalMap);

      uncachedTerms.length &&
        hierarchyProperties &&
        fetch(sparqlUrl, {
          credentials: "same-origin",
          method: "POST",
          headers: { Accept: "application/json" },
          body: new URLSearchParams({
            account: dataset?.owner.accountName,
            dataset: dataset?.name,
            queryString: sparqlFetchExpandable(
              uncachedTerms.map((term) => `<${term.concept}>`).join(" "),
              selectedSchemes,
              hierarchyProperties,
              !schemes || schemes.length > 0,
            ),
          }),
        })
          .then((response) => response.json())
          .then((data: { concept: string; isExpandable: string }[]) => {
            const secondLocalMap: Map<string, boolean> = new Map(firstLocalMap.entries());
            for (const resultItem of data) {
              expandableCache.set(
                conceptSchemesQueryString + ":" + resultItem.concept,
                resultItem.isExpandable === "true",
              );
              secondLocalMap.set(
                conceptSchemesQueryString + ":" + resultItem.concept,
                resultItem.isExpandable === "true",
              );
            }
            setDataExpandable(secondLocalMap);
          })
          .catch(() => {});
    },
    100,
  );

  React.useEffect(() => {
    fetchExpandable(terms, selectedSchemes, conceptSchemesQueryString, hierarchyProperties);
  }, [fetchExpandable, conceptSchemesQueryString, selectedSchemes, terms, hierarchyProperties]);

  return (
    <div className={styles.innerTreeChild} style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
      {virtualItems.map((virtualItem) => {
        const item = topLevelConcepts[virtualItem.index];
        const isExpandable = dataExpandable.get(conceptSchemesQueryString + ":" + item.concept);
        return (
          <TreeTopItem
            virtualizer={rowVirtualizer}
            key={virtualItem.key}
            virtualItem={virtualItem}
            item={item}
            isExpandable={isExpandable}
          />
        );
      })}
    </div>
  );
};
