import { ErrorMessage } from "@hookform/error-message";
import { Alert } from "@mui/material";
import getClassName from "classnames";
import * as React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { FontAwesomeButton, FormField } from "#components/index.ts";
import useApplyPrefixes from "#helpers/hooks/useApplyPrefixes.ts";
import useConstructConsoleUrl from "#helpers/hooks/useConstructConsoleUrl.ts";
import Editor from "./Editors/Editor";
import type { FormValues, Property } from "./Types";
import * as styles from "./style.scss";

export interface PropertyModel {
  nodeKind?: "IRI" | "NestedNode" | "Literal";
  datatype?: string;
  type?: string;
  defaultValue?: string;
  defaultValueLabel?: string;
  required?: boolean;
  editor?: string;
  nestedNodeStem?: string;
  readOnly?: boolean;
  minCount?: string;
  maxCount?: string;
  name?: string;
  path: string;
  description?: string;
}

function getDefaultValue({
  propertyModel,
  wellKnownBaseIri,
}: {
  propertyModel: PropertyModel;
  wellKnownBaseIri: string;
}): Property {
  switch (propertyModel.nodeKind || "Literal") {
    case "IRI":
      return {
        value: propertyModel.defaultValue || "",
        nodeKind: "IRI",
        label: propertyModel.defaultValueLabel || "",
      };
    case "NestedNode":
      return {
        nodeKind: "NestedNode",
        type: propertyModel.type || "",
        value: propertyModel.defaultValue || `${propertyModel.nestedNodeStem || wellKnownBaseIri}i-${uuid()}`,
        properties: {},
      };
    case "Literal":
      return {
        value: propertyModel.defaultValue || "",
        datatype: propertyModel.datatype || "",
        nodeKind: "Literal",
        language: "",
      };
  }
}
const PropertyShape: React.FC<{ propertyModel: PropertyModel; name: `properties.${string}` }> = ({
  propertyModel,
  name,
}) => {
  const applyPrefixes = useApplyPrefixes();
  const wellKnownBaseIri = useConstructConsoleUrl()({ pathname: `/.well-known/genid/` });

  const {
    control,
    getFieldState,
    unregister,
    formState: { errors: formErrors },
  } = useFormContext<FormValues>();

  const minCount = Number(propertyModel.minCount) || 0;
  const maxCount = Number(propertyModel.maxCount) || Number.MAX_SAFE_INTEGER;

  const label = propertyModel.name || applyPrefixes(propertyModel.path);

  const { fields, remove, append, move } = useFieldArray<FormValues>({
    name: name,
    control,
    rules: {
      validate: (value: Array<Property | null> | undefined) => {
        const nonEmptyValues = value?.filter((val) => val?.value?.trim()) || [];
        const count = nonEmptyValues.length;
        if (minCount === 1 && count === 0) {
          return `A value for '${label}' is required.`;
        }
        if (count < minCount) {
          return `${minCount} values are required for '${label}'.`;
        }
        if (maxCount === 1 && count > maxCount) {
          return `Only one value is allowed for '${label}'.`;
        }
        if (count > maxCount) {
          return `Not more than ${maxCount} values are allowed for '${label}'.`;
        }
        return undefined;
      },
    },
  });

  const readOnly = !!propertyModel.readOnly;
  const count = fields.length;
  const containsError = getFieldState(name).error;
  const required = minCount > 0;

  return (
    <FormField
      className={getClassName(styles.formField, { [styles.error]: containsError })}
      label={label}
      helperText={propertyModel.description}
      required={required}
      readOnly={readOnly}
      breakPoint={1}
    >
      <div className="flex column g-7">
        <div className="flex g-7">
          <div className="flex column g-7 grow">
            {fields.map((field, index) => {
              const fieldName = `${name}.${index}` as const;
              return (
                <div key={field.id} className="flex g-5">
                  <Editor name={fieldName} propertyModel={propertyModel} />
                  <div className="flex center">
                    {!readOnly && (
                      <FontAwesomeButton
                        color="error"
                        icon="trash"
                        title="Remove value"
                        onClick={() => {
                          // Reorder the item to the last place, so that we can unregister it. The unregister would otherwise apply to the next item.
                          const lastPlace = fields.length - 1;
                          move(index, lastPlace);
                          remove(lastPlace);
                          // unregister is needed to remove validation. Without setTimeout values of fields can get mixed up.
                          setTimeout(() => unregister(`${name}.${lastPlace}`), 0);
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {count < maxCount && !readOnly && (
            <div className={styles.appendField}>
              <FontAwesomeButton
                icon="plus"
                onClick={() =>
                  append(getDefaultValue({ propertyModel: propertyModel, wellKnownBaseIri: wellKnownBaseIri }), {
                    focusName: `${name}.${fields.length}`,
                  })
                }
                title={`Add new value for '${label}'`}
              />
            </div>
          )}
        </div>
        <ErrorMessage
          errors={formErrors}
          // The state of fieldArray uses `${name}.root` for its errors
          name={`${name}.root`}
          render={({ message, messages }) => {
            if (message || messages) {
              return [
                message ? (
                  <Alert key={`${name}.root`} severity="error" variant="outlined">
                    {message}
                  </Alert>
                ) : null,
                ...Object.entries(messages || {}).map(([type, message]) => (
                  <Alert severity="error" variant="outlined" key={type}>
                    {message}
                  </Alert>
                )),
              ];
            }
          }}
        />
      </div>
    </FormField>
  );
};

export default PropertyShape;
